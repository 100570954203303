<template>
  <v-container fluid class="lighten-4">
    <v-form v-model="valid" ref="form" :action="!isRailway ? '/found-tickets' : 'trs-list'" @submit="submit" class="mb-5"
      md="5">
      <v-row>
        <v-col cols="12" md="4">
          <input-town v-model="depCity" :start="this.src" label="Откуда"></input-town>
        </v-col>
        <v-col cols="1" class="text-center" align-self="center">
          <v-btn block color="orange" @click="swapCities" tabindex="-1">
            <v-icon large>mdi-swap-horizontal</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <input-town v-model="arrCity" :start="this.dest" label="Куда"></input-town>
        </v-col>
        <v-row class="mt-3 ml-10 col-2">
          <v-btn block dark type="submit" elevation="0" color="orange">
            Найти Рейс
          </v-btn>
          <!-- <v-btn @click="correction">correction</v-btn> -->
        </v-row>
      </v-row>
    </v-form>

    <v-row align="center" justify="left" class="mb-0">
      <date-request-changer :date="date" @update:day="changeDay($event, false)">
        <div v-if="back !== ''" class="text-h5">Туда</div>
      </date-request-changer>
      <date-request-changer v-if="back !== ''" :date="back" :min="addDays(new Date(formatDateToDatepicker(date)), -1)"
        @update:day="changeDay($event, true)">
        <div class="text-h5">Обратно</div>
      </date-request-changer>
    </v-row>
    <v-row v-if="loading">
      <v-col>
        <overlay-loader overlay :loading="loading"></overlay-loader>
      </v-col>
    </v-row>

    <v-row v-else-if="searchFound" id="list-head">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="3" class="pr-5">
            <ticket-search-filters-list @drop-filters="dropFilters">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>Цена</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <range-slide-filter v-model="priceRange" side-inputs :max="this.tickets.meta.prices.max"
                      :min="this.tickets.meta.prices.min" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Пересадки</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="transfersList" v-model="transfersSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Вылет</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="departuresList" v-model="departuresSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Прибытие</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="arrivalsList" v-model="arrivalsSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Авиакомпания</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="companiesList" v-model="companiesSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Багаж</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="luggageTypesList" v-model="luggageTypesSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Провайдер</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="providersList" v-model="providersSelected" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Вылет (обратно)</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="departuresBackList" v-model="departuresBackSelected" :hidden="back === ''" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Прибытие (обратно)</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="arrivalsBackList" v-model="arrivalsBackSelected" :hidden="back === ''" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время вылета</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="timeSpacing" v-model="departureTimeSelected" return-object />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время прибытия</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="timeSpacing" v-model="arrivalTimeSelected" return-object />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время вылета (обратно)</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="timeSpacing" v-model="departureTimeSelectedBack" return-object
                      :hidden="back === ''" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время прибытия (обратно)</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <check-box-filter :base="timeSpacing" v-model="arrivalTimeSelectedBack" return-object
                      :hidden="back === ''" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Время полета</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <range-slide-filter v-model="timeRange" side-labels sideLabelsText="ч" :max="3600" :devider="60" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </ticket-search-filters-list>
          </v-col>

          <v-col cols="12" md="9" v-if="sortedFilteredVariants.length > 0">
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4" class="mx-lg-5">
                <v-select :items="sortItems" color="orange" item-color="orange" outlined label="Сортировать"
                  v-model="sortBy"></v-select>
              </v-col>
            </v-row>
            <tickets-list-item v-for="(variant, i) in sortedFilteredVariants.slice(
              (this.page - 1) * this.perPage,
              (this.page - 1) * this.perPage + this.perPage
            )" :key="i" :value="variant" :colorIndex="i % 2" :passengers="passengersList" @Buy="Buy"
              :maxPrice="maxPrice" :minPrice="minPrice" :companiesData="companiesData" :airportsData="airportsData" />

            <v-pagination color="secondary" v-model="page"
              :length="Math.ceil(sortedFilteredVariants.length / perPage)"></v-pagination>
          </v-col>

          <v-col v-else>
            <v-row class="pt-16 mb-16" justify="center" align="center">
              <v-col class="text-center">
                <v-icon class="text--disabled" style="font-size: 200px">la-search
                </v-icon>
                <div class="text-h5 text-md-h4 mb-4">
                  Извините! Результаты не найдены
                </div>
                <div>Попробуйте изменить фильтр</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row v-else class="text-h6 my-16 py-16" justify="center" align="center">
      <v-col class="text-center">
        <v-icon class="text--disabled" style="font-size: 200px">la-search
        </v-icon>
        <div class="text-h5 text-md-h4 mb-5">
          Извините! Мы не нашли билеты на указанный маршрут.
        </div>
        <div>
          <v-btn dark elevation="5" large color="orange" @click="$router.back()">
            Вернуться
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <overlay-loader overlay :loading="filterLoading" :absolute="false"></overlay-loader>
  </v-container>
</template>

<script>
import OverlayLoader from "@/components/UI/OverlayLoader";
import CheckBoxFilter from "@/components/UI/CheckBoxFilter.vue";
import VariantListItem from "@/components/FoundedTicketsPage/VariantListItem.vue";
import TicketsListItem from "@/components/FoundedTicketsPage/TicketsListItem.vue";
import RangeSlideFilter from "@/components/UI/RangeSlideFilter.vue";
import DateRequestChanger from "@/components/UI/DateRequestChanger.vue";
import TicketSearchFiltersList from "@/components/FoundedTicketsPage/TicketSearchFiltersList.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat.js";
import PassengerHelper from "@/mixins/booking/PassengerHelper";
import InputTown from "../components/UI/InputTown.vue";

export default {
  name: "FoundTickets",
  mixins: [ApiCalls, PassengerHelper, DateFormat],
  components: {
    InputTown,
    OverlayLoader,
    VariantListItem,
    DateRequestChanger,
    CheckBoxFilter,
    RangeSlideFilter,
    TicketSearchFiltersList,
    TicketsListItem,
  },
  props: ["src", "dest", "date", "back", "cls", "adults", "kids", "bbs"],
  data() {
    return {
      city: "",
      arrCity: {
        text: "",
        value: {
          code: "",
          name: "",
        },
      },
      depCity: {
        text: "",
        value: {
          code: "",
          name: "",
        },
      },
      srcForSubmit: this.src,
      destForSubmit: this.dest,
      valid: false,
      isRailway: false,
      dep: "departure",
      arr: "arr",
      loading: false,
      searchFound: false,
      filterLoading: false,
      sortedFilteredVariants: [],
      timerFilter: null,
      passengersList: [],
      tickets: [],
      // #region Данные сортировки и фильтрации
      sortItems: [
        {
          text: "Цена: по возрастанию",
          value: 0,
        },
        {
          text: "Цена: по убыванию",
          value: 1,
        },
        {
          text: "Время отбытия: по возрастанию",
          value: 2,
        },
        {
          text: "Время отбытия: по убыванию",
          value: 3,
        },
        {
          text: "Длительность полета: по возрастанию",
          value: 4,
        },
        {
          text: "Длительность полета: по убыванию",
          value: 5,
        },
      ],
      timeSpacing: [
        {
          from: "00:00",
          to: "06:00",
          value: "00:00 - 06:00",
          text: "00:00 - 06:00",
        },
        {
          from: "06:00",
          to: "12:00",
          value: "06:00 - 12:00",
          text: "06:00 - 12:00",
        },
        {
          from: "12:00",
          to: "18:00",
          value: "12:00 - 18:00",
          text: "12:00 - 18:00",
        },
        {
          from: "18:00",
          to: "23:59",
          value: "18:00 - 23:59",
          text: "18:00 - 23:59",
        },
      ],
      transfersList: [],
      companiesList: [],
      luggageTypesList: [],
      providersList: [],
      departuresList: [],
      arrivalsList: [],
      departuresBackList: [],
      arrivalsBackList: [],
      sortBy: 0,
      page: 1,
      perPage: 10,
      allArrivals: [],
      allDepartures: [],
      transfersSelected: [],
      companiesSelected: [],
      luggageTypesSelected: [],
      providersSelected: [],
      departuresSelected: [],
      arrivalsSelected: [],
      departuresBackSelected: [],
      arrivalsBackSelected: [],
      priceRange: [],
      timeRange: [0, 3600],
      departureTimeSelected: [],
      arrivalTimeSelected: [],
      departureTimeSelectedBack: [],
      arrivalTimeSelectedBack: [],
      minPrice: 0.0,
      maxPrice: 0.0,
      // #endregion
      companiesData: [],
      airportsData: {},
      allDepArrCodes: [],
    };
  },
  async mounted() {
    await this.refreshTickets();
    this.passengersList = this.getPassengersData(
      this.adults,
      this.kids,
      this.bbs
    );
    this.priceRange = [
      this.tickets.meta.prices.min,
      this.tickets.meta.prices.max,
    ];
    this.minPrice = this.tickets.meta.prices.min;
    this.maxPrice = this.tickets.meta.prices.max;
    let resp = await this.$axios.get(this.$enums.Endpoints.Data.Carriers);
    this.companiesData = resp.data.filter((company) =>
      this.tickets.meta.carriers.map((c) => c.name).includes(company.name)
    );
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        clearTimeout(this.timerFilter);
        this.timerFilter = setTimeout(() => {
          this.sortedFilteredVariants = this.SortedTickets;
          this.page = 1;
          this.fakeLoading();
        }, 300);
      },
    },
  },
  computed: {
    //компания время полета время вылета/прибытия цена багаж
    TicketsByTravelTime() {
      return this.TicketsByCompany.filter((trip) => {
        if (this.back === "") {
          let lastSegmentIndexOfFirstRoute = trip.routes[0].segments.length - 1;
          return (
            (new Date(
              trip.routes[0].segments[lastSegmentIndexOfFirstRoute].arrival.date
            ) -
              new Date(trip.routes[0].segments[0].departure.date)) /
            60000 >=
            this.timeRange[0] &&
            (new Date(
              trip.routes[0].segments[lastSegmentIndexOfFirstRoute].arrival.date
            ) -
              new Date(trip.routes[0].segments[0].departure.date)) /
            60000 <=
            this.timeRange[1]
          );
        } else {
          let lastSegmentIndexOfFirstRoute = trip.routes[0].segments.length - 1;
          let lastSegmentIndexOfRouteBack = trip.routes[1].segments.length - 1;
          let a =
            (new Date(
              trip.routes[0].segments[lastSegmentIndexOfFirstRoute].arrival.date
            ) -
              new Date(trip.routes[0].segments[0].departure.date) +
              (new Date(
                trip.routes[1].segments[
                  lastSegmentIndexOfRouteBack
                ].arrival.date
              ) -
                new Date(trip.routes[1].segments[0].departure.date))) /
            60000;
          let b =
            (new Date(
              trip.routes[0].segments[lastSegmentIndexOfFirstRoute].arrival.date
            ) -
              new Date(trip.routes[0].segments[0].departure.date) +
              (new Date(
                trip.routes[1].segments[
                  lastSegmentIndexOfRouteBack
                ].arrival.date
              ) -
                new Date(trip.routes[1].segments[0].departure.date))) /
            60000 <=
            this.timeRange[1];
          return a >= this.timeRange[0] && b;
        }
      });
    },
    TicketsByDepartureTime() {
      if (this.departureTimeSelected.length === 0) return this.tickets.trips;
      return this.tickets.trips.filter((trip) => {
        for (let i = 0; i < this.departureTimeSelected.length; i++) {
          if (
            new Date(trip.routes[0].segments[0].departure.date) >=
            new Date(
              trip.routes[0].segments[0].departure.date.replace(
                trip.routes[0].segments[0].departure.date.substring(11, 16),
                this.departureTimeSelected[i].from
              )
            ) &&
            new Date(trip.routes[0].segments[0].departure.date) <=
            new Date(
              trip.routes[0].segments[0].departure.date.replace(
                trip.routes[0].segments[0].departure.date.substring(11, 16),
                this.departureTimeSelected[i].to
              )
            )
          ) {
            return true;
          }
        }
      });
    },
    TicketsByArrivalTime() {
      if (this.arrivalTimeSelected.length === 0)
        return this.TicketsByDepartureTime;
      return this.TicketsByDepartureTime.filter((trip) => {
        let indexOfLastElemSegments = trip.routes[0].segments.length - 1;
        for (let i = 0; i < this.arrivalTimeSelected.length; i++) {
          if (
            new Date(
              trip.routes[0].segments[indexOfLastElemSegments].arrival.date
            ) >=
            new Date(
              trip.routes[0].segments[
                indexOfLastElemSegments
              ].arrival.date.replace(
                trip.routes[0].segments[
                  indexOfLastElemSegments
                ].arrival.date.substring(11, 16),
                this.arrivalTimeSelected[i].from
              )
            ) &&
            new Date(
              trip.routes[0].segments[indexOfLastElemSegments].arrival.date
            ) <=
            new Date(
              trip.routes[0].segments[
                indexOfLastElemSegments
              ].arrival.date.replace(
                trip.routes[0].segments[
                  indexOfLastElemSegments
                ].arrival.date.substring(11, 16),
                this.arrivalTimeSelected[i].to
              )
            )
          ) {
            return true;
          }
        }
      });
    },
    TicketsByDepartureTimeBack() {
      if (this.departureTimeSelectedBack.length === 0)
        return this.TicketsByArrivalTime;
      return this.TicketsByArrivalTime.filter((trip) => {
        for (let i = 0; i < this.departureTimeSelectedBack.length; i++) {
          if (
            new Date(trip.routes[1].segments[0].departure.date) >=
            new Date(
              trip.routes[1].segments[0].departure.date.replace(
                trip.routes[1].segments[0].departure.date.substring(11, 16),
                this.departureTimeSelectedBack[i].from
              )
            ) &&
            new Date(trip.routes[1].segments[0].departure.date) <=
            new Date(
              trip.routes[1].segments[0].departure.date.replace(
                trip.routes[1].segments[0].departure.date.substring(11, 16),
                this.departureTimeSelectedBack[i].to
              )
            )
          ) {
            return true;
          }
        }
      });
    },
    TicketsByArrivalTimeBack() {
      if (this.arrivalTimeSelectedBack.length === 0)
        return this.TicketsByDepartureTimeBack;
      return this.TicketsByDepartureTimeBack.filter((trip) => {
        let indexOfLastElemSegments = trip.routes[1].segments.length - 1;
        for (let i = 0; i < this.arrivalTimeSelectedBack.length; i++) {
          if (
            new Date(
              trip.routes[1].segments[indexOfLastElemSegments].arrival.date
            ) >=
            new Date(
              trip.routes[1].segments[
                indexOfLastElemSegments
              ].arrival.date.replace(
                trip.routes[1].segments[
                  indexOfLastElemSegments
                ].arrival.date.substring(11, 16),
                this.arrivalTimeSelectedBack[i].from
              )
            ) &&
            new Date(
              trip.routes[1].segments[indexOfLastElemSegments].arrival.date
            ) <=
            new Date(
              trip.routes[1].segments[
                indexOfLastElemSegments
              ].arrival.date.replace(
                trip.routes[1].segments[
                  indexOfLastElemSegments
                ].arrival.date.substring(11, 16),
                this.arrivalTimeSelectedBack[i].to
              )
            )
          ) {
            return true;
          }
        }
      });
    },
    TicketsByCompany() {
      if (this.companiesSelected.length === 0)
        return this.TicketsByArrivalTimeBack;
      return this.TicketsByArrivalTimeBack?.filter((trip) =>
        this.companiesSelected.includes(trip.routes[0].segments[0].carrier)
      );
    },
    TicketsByPrice() {
      return this.TicketsByCompany?.filter(
        (trip) =>
          trip.price.total >= this.priceRange[0] &&
          trip.price.total <= this.priceRange[1]
      );
    },
    TicketsByBaggage() {
      if (this.luggageTypesSelected.length === 0) return this.TicketsByPrice;
      return this.TicketsByPrice?.filter((trip) =>
        this.luggageTypesSelected.includes(trip.baggage)
      );
    },
    TicketsByProvider() {
      if (this.providersSelected.length === 0) return this.TicketsByBaggage;
      return this.TicketsByBaggage?.filter((trip) =>
        this.providersSelected.includes(trip.provider)
      );
    },
    TicketsByDepartures() {
      if (this.departuresSelected.length === 0) return this.TicketsByProvider;
      return this.TicketsByProvider.filter((trip) =>
        this.myContains(
          this.departuresSelected,
          this.allDepArrCodes.find((e) => e.id === trip.id).dep
        )
      );
    },
    TicketsByArrivals() {
      if (this.arrivalsSelected.length === 0) return this.TicketsByDepartures;
      return this.TicketsByBaggage.filter((trip) =>
        this.myContains(
          this.arrivalsSelected,
          this.allDepArrCodes.find((e) => e.id === trip.id).arr
        )
      );
    },
    TicketsByDeparturesBack() {
      if (this.departuresBackSelected.length === 0)
        return this.TicketsByArrivals;
      return this.TicketsByArrivals?.filter((trip) =>
        this.departuresBackSelected.includes(
          trip.routes[1].segments[0].departure.code
        )
      );
    },
    TicketsByArrivalsBack() {
      if (this.arrivalsBackSelected.length === 0)
        return this.TicketsByDeparturesBack;
      return this.TicketsByDeparturesBack?.filter((trip) =>
        this.arrivalsBackSelected.includes(
          trip.routes[1].segments[trip.routes[1].segments.length - 1].arrival
            .code
        )
      );
    },
    TicketsByTransfers() {
      if (this.transfersSelected.length === 0)
        return this.TicketsByArrivalsBack;
      return this.TicketsByArrivalsBack?.filter((trip) =>
        this.transfersSelected.includes(
          trip.routes.map((e) => e.segments.length).reduce((a, b) => a + b) -
          trip.routes.length
        )
      );
    },
    SortedTickets() {
      let sortValue = this.sortBy;
      let flyingBack = this.back === "";
      let normalSorted = this.TicketsByTransfers?.sort(function (a, b) {
        switch (sortValue) {
          default:
            return a.price.total < b.price.total ? -1 : 1;
          case 0: //Цена: по возрастанию
            return a.price.total < b.price.total ? -1 : 1;
          case 1: //Цена: по убыванию
            return a.price.total > b.price.total ? -1 : 1;
          case 2: //Время отбытия: по возрастанию
            return a.routes[0].segments[0].departure.date <
              b.routes[0].segments[0].departure.date
              ? -1
              : 1;
          case 3: //Время отбытия: по убыванию
            return a.routes[0].segments[0].departure.date >
              b.routes[0].segments[0].departure.date
              ? -1
              : 1;
          case 4: //Длительность полета: по возрастанию
            if (flyingBack) {
              let indexOfLastElemSegmentsA = a.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsB = b.routes[0].segments.length - 1;
              return new Date(
                a.routes[0].segments[indexOfLastElemSegmentsA].arrival.date
              ) -
                new Date(a.routes[0].segments[0].departure.date) <
                new Date(
                  b.routes[0].segments[indexOfLastElemSegmentsB].arrival.date
                ) -
                new Date(b.routes[0].segments[0].departure.date)
                ? -1
                : 1;
            } else {
              let indexOfLastElemSegmentsA = a.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsB = b.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsABack =
                a.routes[1].segments.length - 1;
              let indexOfLastElemSegmentsBBack =
                b.routes[1].segments.length - 1;

              return new Date(
                a.routes[0].segments[indexOfLastElemSegmentsA].arrival.date
              ) -
                new Date(a.routes[0].segments[0].departure.date) +
                (new Date(
                  a.routes[1].segments[
                    indexOfLastElemSegmentsABack
                  ].arrival.date
                ) -
                  new Date(a.routes[1].segments[0].departure.date)) <
                new Date(
                  b.routes[0].segments[indexOfLastElemSegmentsB].arrival.date
                ) -
                new Date(b.routes[0].segments[0].departure.date) +
                (new Date(
                  b.routes[1].segments[
                    indexOfLastElemSegmentsBBack
                  ].arrival.date
                ) -
                  new Date(b.routes[1].segments[0].departure.date))
                ? -1
                : 1;
            }
          case 5: //Длительность полета: по убыванию
            if (flyingBack) {
              let indexOfLastElemSegmentsA = a.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsB = b.routes[0].segments.length - 1;

              return new Date(
                a.routes[0].segments[indexOfLastElemSegmentsA].arrival.date
              ) -
                new Date(a.routes[0].segments[0].departure.date) >
                new Date(
                  b.routes[0].segments[indexOfLastElemSegmentsB].arrival.date
                ) -
                new Date(b.routes[0].segments[0].departure.date)
                ? -1
                : 1;
            } else {
              let indexOfLastElemSegmentsA = a.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsB = b.routes[0].segments.length - 1;
              let indexOfLastElemSegmentsABack =
                a.routes[1].segments.length - 1;
              let indexOfLastElemSegmentsBBack =
                b.routes[1].segments.length - 1;

              return new Date(
                a.routes[0].segments[indexOfLastElemSegmentsA].arrival.date
              ) -
                new Date(a.routes[0].segments[0].departure.date) +
                (new Date(
                  a.routes[1].segments[
                    indexOfLastElemSegmentsABack
                  ].arrival.date
                ) -
                  new Date(a.routes[1].segments[0].departure.date)) >
                new Date(
                  b.routes[0].segments[indexOfLastElemSegmentsB].arrival.date
                ) -
                new Date(b.routes[0].segments[0].departure.date) +
                (new Date(
                  b.routes[1].segments[
                    indexOfLastElemSegmentsBBack
                  ].arrival.date
                ) -
                  new Date(b.routes[1].segments[0].departure.date))
                ? -1
                : 1;
            }
        }
      });
      return this.$store.getters.user.role != this.$enums.Roles.SubAgent
        ? normalSorted
        // Выдвигает миксвел вперед
        : normalSorted.sort(function (a, b) {
          if (a.provider == "MixVel")
            return (b.provider == "MixVel") ? 0 : -1
          return (b.provider == "MixVel") ? 1 : 0;
        })
    },

    filters() {
      return [
        this.companiesSelected,
        this.luggageTypesSelected,
        this.departuresSelected,
        this.arrivalsSelected,
        this.departuresBackSelected,
        this.arrivalsBackSelected,
        this.departureTimeSelected,
        this.arrivalTimeSelected,
        this.departureTimeSelectedBack,
        this.arrivalTimeSelectedBack,
        this.priceRange,
        this.timeRange,
        this.sortBy,
        this.providersSelected,
        this.transfersSelected,
      ];
    },
  },
  methods: {
    swapCities() {
      let temp = this.depCity;
      this.depCity = this.arrCity;
      this.arrCity = temp;
    },
    async submit(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        this.src = this.depCity.value.code;
        this.dest = this.arrCity.value.code;
        await this.$router.push(
          `/found-tickets?src=${this.depCity.value.code}&dest=${this.arrCity.value.code}&date=${this.date}&back=${this.back}&cls=${this.cls}&adults=${this.adults}&kids=${this.kids}&bbs=${this.bbs}&city1=${this.depCity.value.name}&city2=${this.arrCity.value.name}`
        );
        this.refreshTickets();
      }
    },
    async refreshTickets() {
      this.dropFilters();
      this.dropFiltersTest();
      this.loading = true;
      try {
        this.tickets = await this.getTickets();
        if (this.tickets.trips.length !== null) {
          this.setSortData();
        }
        this.sortedFilteredVariants = this.tickets.trips;
      } catch (e) {
        console.warn(e.response);
      }
      this.loading = false;
    },
    async getTickets() {
      try {
        let req;
        //если this.back пустой, то билет только в одну сторону
        if (this.back !== "") {
          req = {
            Departure: this.src,
            Arrival: this.dest,
            Date: this.date,
            DateBack: this.back,
            Class: Number(this.cls),
            Passengers: [],
          };
        } else {
          req = {
            Departure: this.src,
            Arrival: this.dest,
            Date: this.date,
            Class: Number(this.cls),
            Passengers: [],
          };
        }
        for (let i = 0; i < this.adults; i++) {
          req.Passengers.push("ADT");
        }
        for (let i = 0; i < this.kids; i++) {
          req.Passengers.push("CNN");
        }
        for (let i = 0; i < this.bbs; i++) {
          req.Passengers.push("INF");
        }
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Order.Search,
          req
        );
        return resp.data;
      } catch (e) {
        throw e;
      }
    },
    setSortData() {
      this.transfersList = [
        {
          text: "Без пересадок",
          value: 0,
        },
        {
          text: "1 пересадка",
          value: 1,
        },
        {
          text: "2 пересадки",
          value: 2,
        },
        {
          text: "3 пересадки",
          value: 3,
        },
      ];
      let array = [];
      for (let i = 0; i < this.tickets.trips.length; i++) {
        array.push({
          id: "",
          dep: [],
          arr: [],
        });
        for (let j = 0; j < this.tickets.trips[i].routes.length; j++) {
          for (
            let s = 0;
            s < this.tickets.trips[i].routes[j].segments.length;
            s++
          ) {
            array[i].id = this.tickets.trips[i].id;
            array[i].arr.push(
              this.tickets.trips[i].routes[j].segments[s].arrival.code
            );
            array[i].dep.push(
              this.tickets.trips[i].routes[j].segments[s].departure.code
            );
          }
        }
      }
      this.allDepArrCodes = array;
      this.airportsData = new Map();
      for (
        let i = 0;
        i < this.tickets.meta.transportNodes.arrivals.length;
        i++
      ) {
        let a = this.tickets.meta.transportNodes.arrivals[i];
        this.airportsData.set(a.codeEn, a);
      }
      for (
        let i = 0;
        i < this.tickets.meta.transportNodes.departures.length;
        i++
      ) {
        let a = this.tickets.meta.transportNodes.departures[i];
        this.airportsData.set(a.codeEn, a);
      }
      this.searchFound = this.tickets.trips.length > 0;
      for (let i = 0; i < this.tickets.meta.carriers.length; i++) {
        this.companiesList.push({
          value: this.tickets.meta.carriers[i].code,
          text: this.tickets.meta.carriers[i].name,
        });
      }
      for (let i = 0; i < this.tickets.meta.baggage.length; i++) {
        this.luggageTypesList.push({
          value: this.tickets.meta.baggage[i],
          text: this.tickets.meta.baggage[i],
        });
      }
      ///
      for (let i = 0; i < this.tickets.meta.providers.length; i++) {
        this.providersList.push({
          value: this.tickets.meta.providers[i],
          text: this.tickets.meta.providers[i],
        });
      }
      if (this.back === "") {
        // КАК НАДО
        this.departuresList = this.tickets.meta.transportNodes.departures.map(
          (e) => {
            return {
              value: e.codeEn,
              text: e.nameRu,
            };
          }
        );
        for (
          let i = 0;
          i < this.tickets.meta.transportNodes.arrivals.length;
          i++
        ) {
          this.arrivalsList.push({
            value: this.tickets.meta.transportNodes.arrivals[i].codeEn,
            text: this.tickets.meta.transportNodes.arrivals[i].nameRu,
          });
        }
      } else {
        for (
          let i = 0;
          i < this.tickets.meta.transportNodes.arrivals.length;
          i++
        ) {
          this.arrivalsList.push({
            value: this.tickets.meta.transportNodes.arrivals[i].codeEn,
            text: this.tickets.meta.transportNodes.arrivals[i].nameEn,
          });
          this.departuresList.push({
            value: this.tickets.meta.transportNodes.arrivals[i].codeEn,
            text: this.tickets.meta.transportNodes.arrivals[i].nameEn,
          });
          this.arrivalsBackList.push({
            value: this.tickets.meta.transportNodes.arrivals[i].codeEn,
            text: this.tickets.meta.transportNodes.arrivals[i].nameEn,
          });
          this.departuresBackList.push({
            value: this.tickets.meta.transportNodes.arrivals[i].codeEn,
            text: this.tickets.meta.transportNodes.arrivals[i].nameEn,
          });
        }
      }
    },
    /**
     * Делает новый запрос на другую дату
     * @param {string} date - новая дата
     * @param {Boolean} isBack - Указывает, к чему применить новую дату, туда или обратно
     */
    async changeDay(date, isBack) {
      await this.$router.push(
        `/found-tickets?src=${this.src}&dest=${this.dest}&date=${isBack ? this.date : date
        }&back=${isBack ? date : this.back}&cls=${this.cls}&adults=${this.adults
        }&kids=${this.kids}&bbs=${this.bbs}&city1=${this.depCity.value.name
        }&city2=${this.arrCity.value.name}`
      );
      this.refreshTickets();
    },
    async Buy(variant) {
      await this.$router.push({
        name: "buy-ticket",
        query: {
          adt: Number(this.adults),
          cnn: Number(this.kids),
          inf: Number(this.bbs),
          provider: variant.provider,
          id: variant.id,
        },
      });
    },
    myContains(where, what) {
      for (let i = 0; i < what.length; i++) {
        if (where.includes(what[i])) return true;
      }
      return false;
    },
    dropFilters() {
      this.transfersSelected.splice(0, this.transfersSelected.length);
      this.companiesSelected.splice(0, this.companiesSelected.length);
      this.luggageTypesSelected.splice(0, this.luggageTypesSelected.length);
      this.departuresSelected.splice(0, this.departuresSelected.length);
      this.arrivalsSelected.splice(0, this.arrivalsSelected.length);
      this.departuresBackSelected.splice(0, this.departuresBackSelected.length);
      this.arrivalsBackSelected.splice(0, this.arrivalsBackSelected.length);
      this.departureTimeSelected.splice(0, this.departureTimeSelected.length);
      this.arrivalTimeSelected.splice(0, this.arrivalTimeSelected.length);
      this.departureTimeSelectedBack.splice(
        0,
        this.departureTimeSelectedBack.length
      );
      this.providersSelected.splice(0, this.providersSelected.length);
      this.arrivalTimeSelectedBack.splice(
        0,
        this.arrivalTimeSelectedBack.length
      );
      this.timeRange.splice(0, this.timeRange.length);
      this.timeRange.push(0, 3600);
      this.priceRange.splice(0, this.priceRange.length);
      this.priceRange.push(this.minPrice, this.maxPrice);
    },
    dropFiltersTest() {
      this.arrivalsList.splice(0, this.arrivalsList.length);
      this.departuresList.splice(0, this.departuresList.length);
      this.companiesList.splice(0, this.companiesList.length);
      this.luggageTypesList.splice(0, this.luggageTypesList.length);
      this.providersList.splice(0, this.providersList.length);
    },
    fakeLoading(delay = 500, offset = false) {
      this.filterLoading = true;
      setTimeout(() => {
        this.filterLoading = false;
        if (offset)
          this.$vuetify.goTo("#list-head", {
            offset: 100,
          });
      }, delay);
    },
  },
};
</script>

<style scoped></style>
