<template>
  <v-col md="5" class="text-center">
    <div v-if="$vuetify.breakpoint.xs" class="text-h5 text-md-h4">
      {{ time }}
      <br />
      <span class="text-body-1">{{ date }}</span>
    </div>

    <div v-else class="text-h5 text-md-h4">
      {{ time }}
      <span class="text-body-1">{{ date }}</span>
    </div>

    <v-chip class="d-none d-md-block">
      <v-icon class="mr-1">{{ directionProp }}</v-icon>
      {{ cityName }}
    </v-chip>
    <div class="d-none d-md-block">
      {{ airport }}
    </div>
  </v-col>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "Station",
  props: {
    direction: {
      type: String,
      require: true,
    },
    data: {
      type: Object,
      require: true,
    },
    dateFormat: {
      type: String,
      require: true,
    },
    timeFormat: {
      type: String,
      require: true,
    },
    airportsData: {
      type: Map,
    },
  },
  data() {
    return {};
  },
  methods: {
    FormatMethod(date, formatData) {
      return format(date, formatData);
    },
  },
  computed: {
    directionProp() {
      if (this.direction === "departure") {
        return "la-plane-departure";
      } else if (this.direction === "arrival") {
        return "la-plane-arrival";
      }
    },
    airport() {
      for (const [key, value] of this.airportsData) {
        if (key === this.data.code)
          return value.nameRu.substring(
            value.nameRu.indexOf("(") + 1,
            value.nameRu.indexOf(")")
          ) || value.nameRu
      }
    },
    cityName() {
      if (this.data.name)
        return this.data.name;
      for (const [key, value] of this.airportsData) {
        if (key === this.data.code)
          return value.nameRu.substring(0, value.nameRu.indexOf("(")) || value.nameRu
      }
    },
    time() {
      return this.FormatMethod(new Date(this.data.date), this.timeFormat);
    },
    date() {
      return this.FormatMethod(new Date(this.data.date), this.dateFormat);
    },
  },
};
</script>

<style></style>
